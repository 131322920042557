import { Loader, Message } from "semantic-ui-react";

import { useAuth } from "../../authentication/authContext";
import { useOperator } from "./useOperator";

/** Shows a loading indicator until the operator has been loaded */
export const OperatorWaiter = ({ children }) => {
  const { user } = useAuth();
  const { operator, isFetching } = useOperator();

  if (isFetching) return <Loader active>Loading operator...</Loader>;

  if (!operator) {
    return user && user.role === "admin" ? (
      <Message>Please choose an operator.</Message>
    ) : (
      <Message error>
        Oops, your operator should have been loaded by now. Please contact
        Snowtrade Royale.
      </Message>
    );
  }

  return children;
};
