import React from "react";

import { AuthContext } from "./AuthContext";

/**
 * @typedef {object} AuthContext
 * @property {import("../userType").User | null} user The user logged in or null.
 * @property {any} login A function returning a promise which resolves when the
 *   user is logged in and rejects when it did not work.
 * @property {any} logout A function returning a promise which resolves when the
 *   user is logged out and rejects when it did not work.
 * @property {boolean} isFetching
 */

/** @returns {AuthContext} */
export const useAuth = () => {
  return React.useContext(AuthContext);
};
