import { useEffect, useState } from "react";

import { snowtrade } from "../../api/snowtrade";
import { useOperator } from "../../operator/OperatorContext";

/**
 * @returns {{
 *   fleet: import("../FleetVehicle").FleetVehicle[];
 *   isFetching: boolean;
 *   refetchFleet: function;
 * }}
 */
export const useFleet = () => {
  const { operator } = useOperator();

  const [fleet, setFleet] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const fetchAndUpdateFleet = () => {
    if (!operator) return;
    snowtrade
      .get(`/api/transfer-operators/${operator._id}/fleet`)
      .then((response) => {
        setFleet(response.data.fleet);
      })
      .finally(() => setIsFetching(false));
  };

  useEffect(fetchAndUpdateFleet, [operator]);

  return { fleet, isFetching, refetchFleet: fetchAndUpdateFleet };
};
