import React, { useRef } from "react";

import "./ChannelPreview.css";

/**
 * This component is just copied from
 * https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelPreview/ChannelPreviewMessenger.tsx
 * and adapted to our needs of displaying tls, cc, and client name.
 */
const UnMemoizedChannelPreviewMessenger = (props) => {
  const {
    active,
    channel,
    displayTitle,
    latestMessage,
    setActiveChannel,
    unread,
    watchers,
  } = props;

  const channelPreviewButton = useRef(null);

  const activeClass = active
    ? "str-chat__channel-preview-messenger--active"
    : "";
  const unreadClass =
    unread && unread >= 1 ? "str-chat__channel-preview-messenger--unread" : "";

  const onSelectChannel = () => {
    if (setActiveChannel) {
      setActiveChannel(channel, watchers);
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }
  };

  return (
    <button
      aria-label={`Select Channel: ${displayTitle || ""}`}
      aria-selected={active}
      className={`str-chat__channel-preview-messenger ${unreadClass} ${activeClass}`}
      data-testid="channel-preview-button"
      onClick={onSelectChannel}
      ref={channelPreviewButton}
      role="option"
    >
      <div className="str-chat__channel-preview-messenger--right">
        <div className="str-chat__channel-preview-messenger--name">
          <span style={{ opacity: ".5", fontWeight: 100 }}>Client:</span>{" "}
          <span style={{}}>
            {channel.data.clientFirstName} {channel.data.clientLastName}
          </span>
        </div>
        <div className="str-chat__channel-preview-messenger--last-message">
          {latestMessage}
        </div>
      </div>
    </button>
  );
};

/**
 * Used as preview component for channel item in [ChannelList](#channellist)
 * component. Its best suited for messenger type chat.
 */
export const ChannelPreview = React.memo(UnMemoizedChannelPreviewMessenger);
