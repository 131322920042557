import React from "react";

import { serializeError } from "serialize-error";

import { snowtrade } from "../api/snowtrade";
import { AuthContext } from "../authentication/authContext";
import { ErrorPage } from "./ErrorPage";

export class BaseErrorBoundary extends React.Component {
  state = { error: null };
  static contextType = AuthContext;

  componentDidCatch(error) {
    if (error.toString().match(/loading chunk .* failed/i)) {
      window.location.reload();
      return;
    }

    this.setState({ error });
    snowtrade.post("/api/mailer", {
      subject: "Frontend Crashed",
      error: serializeError(error),
      location: window.location,
      user: this.context.user,
    });
  }

  render() {
    if (this.state.error) {
      return <ErrorPage error={this.state.error} />;
    }

    return this.props.children;
  }
}
