// @ts-check
import { find, get } from "lodash";

/**
 * @param {import("../../fleet/FleetVehicle").FleetVehicle[]} fleet
 * @param {import("../TransferRoute").TransferRouteVehicle[]} routeVehicles
 * @returns {import("./RoutesEditorContainer").RouteFormVehicle[]}
 */
export const mapFleetVehiclesToFormVehicles = (fleet, routeVehicles) => {
  return fleet.map((vehicle) => {
    const matchingRouteVehicle = find(routeVehicles, {
      vehicleId: vehicle._id,
    });

    return {
      id: vehicle._id,
      name: vehicle.name,
      basePrice: get(matchingRouteVehicle, "price.base") || "",
      currency: get(matchingRouteVehicle, "price.currency") || "EUR",
    };
  });
};
