import { isEmpty } from "lodash";
import { Button, Checkbox, Segment } from "semantic-ui-react";

import { FromToFormSegment } from "./FromToFormSegment";
import { RouteVehiclesTable } from "./RouteVehiclesTable";

export const RouteEditorView = ({
  onSaveClick,
  fromLocationId,
  toLocationId,
  setFromLocationId,
  setToLocationId,
  formVehicles,
  onNewFormVehicles,
  isSaving,
  isApplyToReturnTripChecked,
  setIsApplyToReturnTripChecked,
}) => {
  return (
    <>
      <FromToFormSegment
        fromLocationId={fromLocationId}
        toLocationId={toLocationId}
        setFromLocationId={setFromLocationId}
        setToLocationId={setToLocationId}
      />

      {!isEmpty(formVehicles) && (
        <>
          <RouteVehiclesTable
            vehicles={formVehicles}
            onVehiclesChange={onNewFormVehicles}
          />
          <Segment basic style={{ padding: 0 }}>
            <Checkbox
              label="Apply to return trip too"
              checked={isApplyToReturnTripChecked}
              onChange={(e, { checked }) =>
                setIsApplyToReturnTripChecked(checked)
              }
            />
          </Segment>

          <Button primary onClick={onSaveClick} loading={isSaving}>
            Save route
          </Button>
        </>
      )}
    </>
  );
};
