import { useState } from "react";

import { toast } from "react-toastify";

import { snowtrade } from "../../api/snowtrade";
import { mapFormVehiclesToRouteVehicles } from "./mapFormVehiclesToRouteVehicles";

export const useRouteSaver = ({
  transferOperatorId,
  fromLocationId,
  toLocationId,
  onRouteSaved,
  isApplyToReturnTripChecked,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const saveRoute = (formVehicles) => {
    setIsSaving(true);
    const routeVehiclesToSend = mapFormVehiclesToRouteVehicles(formVehicles);

    const savePromises = [
      putRoute({
        fromLocationId,
        toLocationId,
        transferOperatorId,
        vehicles: routeVehiclesToSend,
      }).then(onRouteSaved),
    ];

    if (isApplyToReturnTripChecked) {
      savePromises.push(
        putRoute({
          fromLocationId: toLocationId,
          toLocationId: fromLocationId,
          transferOperatorId,
          vehicles: routeVehiclesToSend,
        }).then(onRouteSaved)
      );
    }

    Promise.all(savePromises)
      .then(() => toast.success("Successfully saved this route"))
      .catch(() => toast.error("Unfortunately, an error occured"))
      .finally(() => setIsSaving(false));
  };

  return { saveRoute, isSaving };
};

const putRoute = ({
  transferOperatorId,
  fromLocationId,
  toLocationId,
  vehicles,
}) => {
  return snowtrade
    .put(
      `/api/transfer-operators/${transferOperatorId}/routes/from/${fromLocationId}/to/${toLocationId}`,
      { vehicles }
    )
    .then((response) => response.data);
};
