import { useState } from "react";

import { toast } from "react-toastify";
import { Button, Modal } from "semantic-ui-react";

import { snowtrade } from "../../api/snowtrade";
import { LocationForm } from "../LocationForm";

export const NewLocationButton = ({ onLocationCreated }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen((wasOpen) => !wasOpen);

  const handleLocationCreated = () => {
    toggleModal();
    onLocationCreated();
  };

  return (
    <>
      <Button onClick={toggleModal}>New location</Button>
      {isModalOpen && (
        <Modal open closeIcon onClose={toggleModal}>
          <Modal.Header>New Location</Modal.Header>
          <Modal.Content>
            <LocationCreator onLocationCreated={handleLocationCreated} />
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

const LocationCreator = ({ onLocationCreated }) => {
  const [isPosting, setIsPosting] = useState(false);

  const handleValidatedSubmit = (newLocation) => {
    setIsPosting(true);
    snowtrade
      .post("/api/transfer-locations", newLocation)
      .then(onLocationCreated)
      .catch((error) => {
        console.error(error);
        toast.error("Unfortunately, an error occured");
        setIsPosting(false);
      });
  };

  return (
    <LocationForm
      onValidatedSubmit={handleValidatedSubmit}
      loading={isPosting}
    />
  );
};
