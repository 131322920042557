import { useEffect, useState } from "react";

import { snowtrade } from "../../api/snowtrade";

/**
 * @param {string} helicopterId
 * @returns {{
 *   isFetching: boolean;
 *   helicopter: import("../Helicopter").Helicopter;
 * }}
 */
export const useHelicopterById = (helicopterId) => {
  const [helicopter, setHelicopter] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    snowtrade
      .get(`/api/helicopters/${helicopterId}`)
      .then((response) => setHelicopter(response.data))
      .finally(() => setIsFetching(false));
  }, []);

  return { helicopter, isFetching };
};
