import { useEffect, useState } from "react";

import { toast } from "react-toastify";

import { snowtrade } from "../../api/snowtrade";
import { useAuth } from "../../authentication/authContext";
import { OperatorContext } from "./OperatorContext";

/**
 * TESTING ADVISE: Wrap this component in the `AuthProvider` AND the
 * `RequireAuth` component to assure that the current user has been fetched correctly.
 */
export const OperatorProvider = ({ children }) => {
  const { user } = useAuth();

  const shouldFetchMyOperator = user.role === "operator";

  const [operator, setOperator] = useState();
  const [isFetching, setIsFetching] = useState(shouldFetchMyOperator);

  useEffect(() => {
    if (!shouldFetchMyOperator) return;
    if (!isFetching) setIsFetching(true);
    const abortController = new AbortController();
    snowtrade
      .get("/api/transfer-operators/my-operator", {
        signal: abortController.signal,
      })
      .then((response) => {
        if (!abortController.signal.aborted) setOperator(response.data);
      })
      .catch(() => toast.error("Could not fetch operator"))
      .finally(() => {
        if (!abortController.signal.aborted) setIsFetching(false);
      });

    return () => abortController.abort();
  }, [user]);

  const hardSetOperator = (operator) => {
    setOperator(operator);
    if (isFetching) setIsFetching(false);
  };

  return (
    <OperatorContext.Provider value={{ operator, isFetching, hardSetOperator }}>
      {children}
    </OperatorContext.Provider>
  );
};
