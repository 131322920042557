import NumberFormat from "react-number-format";
import { Input } from "semantic-ui-react";

export const VehiclePriceInput = ({ value, currency, onUpdate }) => {
  return (
    <NumberFormat
      placeholder="Price per vehicle"
      onValueChange={({ value }) => onUpdate({ basePrice: value })}
      thousandSeparator
      value={value}
      customInput={Input}
      fluid
      prefix={`${currency} `}
      decimalScale={2}
      onFocus={(e) => e.target.select()}
    />
  );
};
