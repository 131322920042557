import { useEffect, useState } from "react";

import { snowtrade } from "../../api/snowtrade";
import { sendLoginRequest, sendLogoutRequest } from "./api";
import { AuthContext } from "./AuthContext";

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    snowtrade
      .get("/api/current_user", { signal: abortController.signal })
      .then((response) => setUser(response.data))
      .catch(() => {})
      .finally(() => {
        if (abortController.signal.aborted) return;
        setIsFetching(false);
      });

    const cleanUp = () => abortController.abort();

    return cleanUp;
  }, []);

  const login = (credentials) =>
    sendLoginRequest(credentials).then((logInData) => {
      localStorage.setItem("auth", JSON.stringify(logInData));
      setUser(logInData.user);
    });

  const logout = () =>
    sendLogoutRequest().then(() => {
      localStorage.removeItem("auth");
      setUser(null);
    });

  const value = { user, login, logout, isFetching };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
