import { Container, Header } from "semantic-ui-react";

import { FleetTable } from "./FleetTable";

export const FleetPage = () => {
  return (
    <Container>
      <Header as="h1">My Fleet</Header>
      <FleetTable />
    </Container>
  );
};
