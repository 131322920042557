import { useState } from "react";

import { Form, Header, Segment } from "semantic-ui-react";

import "./LoginPage.css";
import { useLogin } from "./useLogin";

export const LoginPage = () => {
  const { logIn, isLoading } = useLogin();
  const [formState, setFormState] = useState({ username: "", password: "" });

  const changeHandler = ({ target: { name, value } }) =>
    setFormState((oldFormState) => ({ ...oldFormState, [name]: value }));

  const onLoginClick = () => logIn(formState);

  return (
    <div className="loginPage">
      <Segment className="colored">
        <Header>Log in</Header>
        <Form>
          <Form.Input
            id="username-input"
            label="Username"
            onChange={changeHandler}
            name="username"
            value={formState.username}
          />
          <Form.Input
            id="password-input"
            label="Password"
            type="password"
            onChange={changeHandler}
            name="password"
            value={formState.password}
          />
          <Form.Button primary onClick={onLoginClick} loading={isLoading}>
            Submit
          </Form.Button>
        </Form>
      </Segment>
    </div>
  );
};
