import { useState } from "react";

import { toast } from "react-toastify";

import { snowtrade } from "../api/snowtrade";
import { LocationForm } from "./LocationForm";

export const LocationEditor = ({ location, onLocationEdited }) => {
  const [isPatching, setIsPatching] = useState(false);

  const handleValidatedSubmit = (newLocation) => {
    setIsPatching(true);
    snowtrade
      .patch(`/api/transfer-locations/${location._id}`, newLocation)
      .then(() => {
        toast.success("You successfully edited this location");
        onLocationEdited();
      })
      .catch(() => {
        toast.error("Unfortunately, an error occured");
        setIsPatching(false);
      });
  };

  return (
    <LocationForm
      initialFormState={location}
      onValidatedSubmit={handleValidatedSubmit}
      loading={isPatching}
    />
  );
};
