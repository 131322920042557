import { useState } from "react";

import { toast } from "react-toastify";

import { snowtrade } from "../../api/snowtrade";

export const useHelicopterSaver = (helicopterId) => {
  const [isSaving, setIsSaving] = useState(false);

  const saveHelicopter = (formState) => {
    setIsSaving(true);
    snowtrade
      .put(`/api/helicopters/${helicopterId}`, formState)
      .then(() => toast.success("Helicopter saved successfully"))
      .catch(() => toast.error("Unfortunately, an error occured"))
      .finally(() => setIsSaving(false));
  };

  return { saveHelicopter, isSaving };
};
