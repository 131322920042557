import { Link } from "react-router-dom";
import ReactTable from "react-table-v6";
import { Icon } from "semantic-ui-react";

import { SyncImagesButton } from "./SyncImagesButton";

export const FleetTable = ({ fleet, isLoading }) => {
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (row) => (
        <Link to={`${row.original._id}/editor`}>
          {row.original.name || "Edit"}
        </Link>
      ),
    },
    {
      Header: "Published?",
      accessor: "isPublished",
      Cell: (row) => {
        return row.original.isPublished && <Icon name="check" />;
      },
      maxWidth: 80,
    },
    {
      Header: "Sync images",
      Cell: (row) => <SyncImagesButton helicopter={row.original} />,
      maxWidth: 100,
    },
  ];

  return (
    <ReactTable
      columns={columns}
      data={fleet}
      defaultPageSize={10}
      className="-striped -highlight"
      loading={isLoading}
    />
  );
};
