import { useEffect, useState } from "react";

import { snowtrade } from "../api/snowtrade";
import { useOperator } from "../operator/OperatorContext";

/**
 * @returns {{
 *   routes: import("./TransferRoute").TransferRoute[];
 *   refetchRoutes: function;
 * }}
 */
export const useOperatorTransferRoutes = () => {
  const { operator } = useOperator();

  const [routes, setRoutes] = useState([]);

  const fetchAndSetRoutes = () =>
    snowtrade
      .get(`/api/transfer-operators/${operator._id}/routes`)
      .then((response) => setRoutes(response.data));

  useEffect(() => {
    fetchAndSetRoutes();
  }, []);

  return { routes, refetchRoutes: fetchAndSetRoutes };
};
