import { useState } from "react";

import { toast } from "react-toastify";
import { Form } from "semantic-ui-react";

import { useLocationFormValidation } from "./useLocationFormValidation";

const INITIAL_FORMSTATE = {
  name: "",
  city: "",
  country: "",
};

export const LocationForm = ({
  loading,
  onValidatedSubmit,
  initialFormState,
}) => {
  const [formState, setFormState] = useState(
    initialFormState || INITIAL_FORMSTATE
  );
  const { error, validateForm, isValidationActive, activateValidation } =
    useLocationFormValidation(formState);

  const handleChangeEvent = ({ target: { name, value } }) =>
    setFormState({ ...formState, [name]: value });

  const handleOnSubmitClick = () => {
    const { value, isValid } = validateForm();

    if (isValid) {
      onValidatedSubmit(value);
      return;
    }

    activateValidation();
    toast.error("Please fill in the form as advised");
  };

  return (
    <Form>
      <Form.Input
        label="Name"
        placeholder="Name of the location"
        id="location-name-input"
        name="name"
        value={formState.name}
        onChange={handleChangeEvent}
        error={(isValidationActive && error.name) || null}
      />
      <Form.Group widths="equal">
        <Form.Input
          label="City"
          id="location-city-input"
          placeholder="Name of the city"
          name="city"
          value={formState.city}
          onChange={handleChangeEvent}
          error={(isValidationActive && error.city) || null}
        />
        <Form.Input
          label="Country"
          id="location-country-input"
          placeholder="Name of the country"
          name="country"
          value={formState.country}
          onChange={handleChangeEvent}
          error={(isValidationActive && error.country) || null}
        />
      </Form.Group>
      <Form.Button primary onClick={handleOnSubmitClick} loading={loading}>
        Save location
      </Form.Button>
    </Form>
  );
};
