import { useEffect, useState } from "react";

import { Dropdown } from "semantic-ui-react";

import { snowtrade } from "../../../api/snowtrade";
import { useOperator } from "../../../operator/OperatorContext";
import { persistLastOperatorId, retrieveLastOperatorId } from "./persist";

import "./AdminUserItem.css";

export const OperatorDropdown = () => {
  const { operator: activeOperator, hardSetOperator } = useOperator();

  const [operators, setOperators] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    snowtrade
      .get("/api/transfer-operators")
      .then((response) => {
        setOperators(response.data);
        const lastSelectedOperator = findOperatorLastSelectedByAdmin(
          response.data
        );
        if (lastSelectedOperator) hardSetOperator(lastSelectedOperator);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const onChange = (e, { value: operatorId }) => {
    const operator = operators.find((h) => h._id === operatorId);
    hardSetOperator(operator);
    persistLastOperatorId(operatorId);
  };

  return (
    <Dropdown
      selection
      id="operator-selection-dropdown"
      loading={isLoading}
      options={operators.map((o) => ({ text: o.name, value: o._id }))}
      onChange={onChange}
      search
      value={activeOperator ? activeOperator._id : undefined}
    />
  );
};

const findOperatorLastSelectedByAdmin = (operators) => {
  const lastOperatorId = retrieveLastOperatorId();
  if (!lastOperatorId) return;
  return operators.find((h) => h._id === lastOperatorId);
};
