import { useState } from "react";

import { Icon, Modal } from "semantic-ui-react";

import { TOOLTIP_SHOW_DELAY } from "../../config";
import { Tooltip } from "../../Tooltip";
import { LocationEditor } from "../LocationEditor";

export const EditLocationButton = ({ location, onLocationEdited }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen((wasOpen) => !wasOpen);

  const handleLocationEdited = () => {
    toggleModal();
    onLocationEdited();
  };

  return (
    <>
      <Tooltip tooltip="Edit location" delayShow={TOOLTIP_SHOW_DELAY}>
        <Icon
          name="edit"
          onClick={toggleModal}
          link
          aria-label="Edit location"
          role="button"
        />
      </Tooltip>
      {isModalOpen && (
        <Modal open closeIcon onClose={toggleModal}>
          <Modal.Header>Edit Location</Modal.Header>
          <Modal.Content>
            <LocationEditor
              location={location}
              onLocationEdited={handleLocationEdited}
            />
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
