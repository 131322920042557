import { Header } from "semantic-ui-react";
import { TypingIndicator, useChannelStateContext } from "stream-chat-react";

export const ChannelHeader = () => {
  const { channel } = useChannelStateContext();

  return (
    <div
      className="str-chat__header-livestream"
      style={{ justifyContent: "space-between" }}
    >
      <div>
        <Header style={{ margin: 0 }}>
          Client: {channel.data.clientFirstName} {channel.data.clientLastName} |{" "}
          {channel.data.travelAgencyName}
        </Header>
        <TypingIndicator />
      </div>
    </div>
  );
};
