import { useState } from "react";

import { Button } from "semantic-ui-react";

import { snowtrade } from "../../api/snowtrade";
import { useOperator } from "../../operator/OperatorContext";
import { useFleet } from "../hooks/useFleet";
import { FleetTable } from "./FleetTable";

export const FleeTableContainer = () => {
  const { operator } = useOperator();

  const { fleet, isFetching, refetchFleet } = useFleet();

  const [isCreating, setIsCreating] = useState(false);
  const createNewHelicopter = () => {
    setIsCreating(true);
    snowtrade
      .post(`/api/helicopters`, { transferOperatorId: operator._id })
      .then(refetchFleet);
  };

  return (
    <>
      <FleetTable fleet={fleet} isLoading={isFetching || isCreating} />
      <Button onClick={createNewHelicopter}>New helicopter</Button>
    </>
  );
};
