import { Link } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";

import { useAuth } from "../../authentication/authContext";

import "./HotelManagerItem.css";

export const HotelManagerItem = () => {
  const { user } = useAuth();

  const userString = `${user.firstName} ${user.lastName}`;

  return (
    <Menu.Item position="right" className="user" link as={Link} to="/logout">
      <Icon name="user" />
      {userString}
    </Menu.Item>
  );
};
