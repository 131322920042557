import { Card } from "semantic-ui-react";
import { Attachment } from "stream-chat-react";

export const CustomAttachment = (props) => {
  const { attachments } = props;

  if (attachments && attachments[0].type === "hotelLeadAttachment") {
    const [attachment] = attachments;
    return <HotelLeadAttachment attachment={attachment} />;
  }

  return <Attachment {...props} />;
};

const HotelLeadAttachment = () => {
  return (
    <Card style={{ width: "unset" }}>
      <Card.Content>You have sent an offer</Card.Content>
    </Card>
  );
};
