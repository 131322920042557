import { NavLink } from "react-router-dom";
import { Container, Menu as SemanticMenu } from "semantic-ui-react";

import { useAuth } from "../authentication/authContext";
import { useOperator } from "../operator/OperatorContext";
import { UnreadMessagesItem } from "./UnreadMessagesItem";
import { UserItem } from "./UserItem";

import "./Menu.css";

export const Menu = () => {
  const { operator } = useOperator();
  const { user } = useAuth();

  return (
    <SemanticMenu className="topMenu">
      <Container>
        <NavLink to="/">
          <SemanticMenu.Item header link role="heading">
            {operator && operator.name} <UnreadMessagesItem />
          </SemanticMenu.Item>
        </NavLink>

        <NavLink to="fleet" className={getNavLinkClassName}>
          <SemanticMenu.Item link>Fleet</SemanticMenu.Item>
        </NavLink>
        <NavLink to="routes" className={getNavLinkClassName}>
          <SemanticMenu.Item link>Routes</SemanticMenu.Item>
        </NavLink>
        {user.role === "admin" && (
          <NavLink to="locations" className={getNavLinkClassName}>
            <SemanticMenu.Item link>Locations</SemanticMenu.Item>
          </NavLink>
        )}

        {/* <NavLink to="rooms" className={getNavLinkClassName}>
          <SemanticMenu.Item link>Rooms</SemanticMenu.Item>
        </NavLink>

        <NavLink to="rates" className={getNavLinkClassName}> 
          <SemanticMenu.Item link>Rates</SemanticMenu.Item>
        </NavLink> */}
        <UserItem />
      </Container>
    </SemanticMenu>
  );
};

const getNavLinkClassName = ({ isActive }) =>
  isActive ? "active" : "inactive";
