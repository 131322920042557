import { useEffect, useState } from "react";

import { useHelicopterById } from "../hooks/useHelicopterById";

/**
 * @param {string} helicopterId
 * @returns {{ formState: import("../Helicopter").Helicopter }}
 */
export const useHelicopterEditorFormState = (helicopterId) => {
  const { helicopter, isFetching } = useHelicopterById(helicopterId);

  const [formState, setFormState] = useState(null);

  useEffect(() => {
    setFormState(helicopter);
  }, [helicopter]);

  const updateFormState = (update) => setFormState({ ...formState, ...update });
  const handleChangeEvent = ({ target: { name, value } }) =>
    updateFormState({ [name]: value });

  const handleNumberChange = ({ floatValue }, { event }) =>
    updateFormState({ [event.target.name]: floatValue });

  const handleCheckboxClick = (e, { name, checked }) => {
    updateFormState({ [name]: checked });
  };

  return {
    formState,
    handleChangeEvent,
    handleNumberChange,
    handleCheckboxClick,
    isFetching: isFetching || !formState,
  };
};
