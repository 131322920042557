import { DeviceUUID } from "device-uuid";

const STORAGE_KEY = "device-uuid";

let deviceId = localStorage.getItem(STORAGE_KEY);

if (!deviceId) {
  deviceId = new DeviceUUID().get();
  localStorage.setItem(STORAGE_KEY, deviceId);
}

export { deviceId };
