import { Accordion, Grid, Header } from "semantic-ui-react";

import "./ErrorPage.css";

export const ErrorPage = ({ error }) => {
  const panels = [
    {
      title: "More details for nerds",
      content: error.toString() + error.stack.toString(),
      key: "oneAndOnlyButNecessaryKey",
    },
  ];

  return (
    <Grid centered columns={2} style={{ height: "100vh" }} textAlign="left">
      <Grid.Column verticalAlign="middle">
        <Header>Ooops</Header>
        <p>Unfortunately, a severe error occured.</p>
        <Accordion panels={panels} />
      </Grid.Column>
    </Grid>
  );
};
