import { useEffect, useState } from "react";

import { snowtrade } from "../api/snowtrade";

export const useTransferLocations = () => {
  const [locations, setLocations] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const fetchAndUpdateLocations = () => {
    setIsFetching(true);
    snowtrade
      .get("/api/transfer-locations")
      .then((response) => setLocations(response.data.locations || []))
      .finally(() => setIsFetching(false));
  };

  useEffect(fetchAndUpdateLocations, []);

  return { locations, isFetching, refetchLocations: fetchAndUpdateLocations };
};
