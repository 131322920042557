import { useAuth } from "../../authentication/authContext";
import { AdminUserItem } from "./AdminUserItem";
import { HotelManagerItem } from "./HotelManagerItem";

export const UserItem = () => {
  const { user } = useAuth();

  if (!user) return null;

  const isAdmin = user.role === "admin";

  return isAdmin ? <AdminUserItem /> : <HotelManagerItem />;
};
