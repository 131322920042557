import axios from "axios";

import { baseURL } from "./baseUrl";
import { makeAuthorizationHeader } from "./makeAuthorizationHeader";

export let snowtrade = axios.create({
  baseURL,
  timeout: 330000,
});

// Set an interceptor to apply the bearer token to the request header.
snowtrade.interceptors.request.use((config) => {
  const Authorization = makeAuthorizationHeader();
  config.headers.Authorization = Authorization;
  return config;
});
