// @ts-check

import { compact } from "lodash";

/**
 * Additionally removes vehicles without a price from the resulting array
 *
 * @param {import("./RoutesEditorContainer").RouteFormVehicle[]} formVehicles
 * @returns {import("../TransferRoute").TransferRouteVehicle[]}
 */
export const mapFormVehiclesToRouteVehicles = (formVehicles) => {
  return compact(
    formVehicles.map((formVehicle) => {
      if (!formVehicle.basePrice) return null;
      return {
        vehicleId: formVehicle.id,
        price: {
          base: formVehicle.basePrice,
          currency: formVehicle.currency,
        },
      };
    })
  );
};
