import "stream-chat-react/dist/css/index.css";
import "./Chat.css";

import { Loader } from "semantic-ui-react";
import {
  Channel,
  MessageInput,
  MessageList,
  Thread,
  Window,
  ChannelList,
  Chat as StreamChatProvider,
} from "stream-chat-react";

import { useAuth } from "../authentication/authContext";
import { useOperator } from "../operator/OperatorContext";
import { ChannelHeader } from "./ChannelHeader";
import { ChannelPreview } from "./ChannelPreview";
import { useChatClient } from "./ChatClientContext";
import { CustomAttachment } from "./CustomAttachment";

const sort = { last_message_at: -1 };

export const Chat = () => {
  const { user } = useAuth();
  const { operator } = useOperator();
  const { client } = useChatClient();

  if (!client) {
    return <Loader active>Connect to chat</Loader>;
  }

  const filters = { transferOperatorId: operator._id };
  if (user.role !== "admin") {
    filters.members = { $in: [user._id] };
  }

  return (
    <StreamChatProvider client={client}>
      <ChannelList filters={filters} sort={sort} Preview={ChannelPreview} />
      <Channel Attachment={CustomAttachment}>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput grow />
        </Window>
        <Thread />
      </Channel>
    </StreamChatProvider>
  );
};
