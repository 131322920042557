// @ts-check
import { useEffect, useState } from "react";

import { find, isEmpty } from "lodash";

import { useFleet } from "../../fleet/hooks/useFleet";
import { useOperator } from "../../operator/OperatorContext";
import { mapFleetVehiclesToFormVehicles } from "./mapFleetVehiclesToFormVehicles";

/**
 * @param {{
 *   fromLocationId: string;
 *   toLocationId: string;
 *   routes: import("../TransferRoute").TransferRoute[];
 * }} props
 * @returns {{
 *   vehicles: import("./RoutesEditorContainer").RouteFormVehicle[];
 *   onNewVehicles: function;
 * }}
 */
export const useRouteFormVehicles = ({
  fromLocationId,
  toLocationId,
  routes,
}) => {
  const { fleet } = useFleet();
  const { operator } = useOperator();

  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    if (!fromLocationId || !toLocationId || isEmpty(fleet)) return;

    const existingRoute = find(routes, {
      transferOperatorId: operator._id,
      fromLocationId,
      toLocationId,
    });
    const routeVehicles = (existingRoute && existingRoute.vehicles) || [];
    const newVehicles = mapFleetVehiclesToFormVehicles(fleet, routeVehicles);
    setVehicles(newVehicles);
  }, [fromLocationId, toLocationId, fleet, routes]);

  const onNewVehicles = (newVehicles) => {
    setVehicles(newVehicles);
  };

  return { vehicles, onNewVehicles };
};
