// @ts-check
import TooltipTrigger from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";

/**
 * @param {import("react-popper-tooltip").TooltipTriggerProps & {
 *   active: boolean;
 * }} props
 */
export const Tooltip = ({ children, tooltip, active = true, ...props }) =>
  active ? (
    <TooltipTrigger
      {...props}
      tooltip={({
        arrowRef,
        tooltipRef,
        getArrowProps,
        getTooltipProps,
        placement,
      }) => (
        <div
          {...getTooltipProps({
            ref: tooltipRef,
            className: "tooltip-container",
          })}
        >
          <div
            {...getArrowProps({
              ref: arrowRef,
              className: "tooltip-arrow",
              "data-placement": placement,
            })}
          />
          {tooltip}
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef }) => (
        <span
          {...getTriggerProps({
            ref: triggerRef,
            className: "trigger ",
          })}
        >
          {children}
        </span>
      )}
    </TooltipTrigger>
  ) : (
    children
  );
