import { useState } from "react";

import { lowerCase } from "lodash";
import { toast } from "react-toastify";
import { Button, Icon } from "semantic-ui-react";

import { snowtrade } from "../../api/snowtrade";
import { useOperator } from "../../operator/OperatorContext";

export const SyncImagesButton = ({ helicopter }) => {
  const { operator } = useOperator();
  const [isSyncing, setIsSyncing] = useState(false);

  const syncImages = () => {
    setIsSyncing(true);
    snowtrade
      .get(`/api/helicopters/${helicopter._id}/images-update`)
      .then(() => toast.success("Success"))
      .catch(() => {
        const operatorName = lowerCase(operator.name);
        const helicopterName = lowerCase(helicopter.name);
        const path = `str-transfer-vehicle-images/${operatorName}/${helicopterName}`;
        toast.error(`Could not fetch images for ${path} `);
      })
      .finally(() => setIsSyncing(false));
  };

  return (
    <Button icon compact size="tiny" loading={isSyncing} onClick={syncImages}>
      <Icon name="image" />
      <Icon name="redo" />
    </Button>
  );
};
