// @ts-check
import { useEffect, useState } from "react";

import { findIndex } from "lodash";

import { useOperatorTransferRoutes } from "../useOperatorTransferRoutes";

/**
 * Use this to cache transfer routes when editing multiple of them.
 *
 * @returns {{
 *   localRoutes: import("../TransferRoute").TransferRoute[];
 *   upsertRoute: function;
 * }}
 */
export const useCachedLocalRoutes = () => {
  const { routes: dbRoutes } = useOperatorTransferRoutes();

  const [localRoutes, setLocalRoutes] = useState(dbRoutes);

  useEffect(() => {
    setLocalRoutes(dbRoutes);
  }, [dbRoutes]);

  /** @param {import("../TransferRoute").TransferRoute} route */
  const upsertRoute = (route) =>
    setLocalRoutes((localRoutesBefore) => {
      const newRoutes = [...localRoutesBefore];
      const existingRouteIndex = findIndex(localRoutes, { _id: route._id });
      if (existingRouteIndex >= 0) {
        newRoutes[existingRouteIndex] = route;
      } else {
        newRoutes.push(route);
      }
      return newRoutes;
    });

  return { localRoutes, upsertRoute };
};
