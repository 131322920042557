import { isEmpty } from "lodash";
import { Form, Segment } from "semantic-ui-react";

import { useLocationOptions } from "../useLocationOptions";

export const FromToFormSegment = ({
  fromLocationId,
  toLocationId,
  setFromLocationId,
  setToLocationId,
}) => {
  const options = useLocationOptions();

  return (
    <Segment className="colored">
      <Form>
        <Form.Group widths="equal">
          <Form.Select
            id="from-select"
            label="From"
            data-testid="from-testid"
            options={options}
            loading={isEmpty(options)}
            value={fromLocationId}
            onChange={(e, { value }) => setFromLocationId(value)}
            search
          />
          <Form.Select
            id="to-select"
            label="To"
            data-testid="to-testid"
            options={options}
            loading={isEmpty(options)}
            value={toLocationId}
            onChange={(e, { value }) => setToLocationId(value)}
            search
          />
        </Form.Group>
      </Form>
    </Segment>
  );
};
