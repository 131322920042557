import NumberFormat from "react-number-format";
import { Form, Loader, Segment } from "semantic-ui-react";

import { useHelicopterEditorFormState } from "./useHelicopterEditorFormState";
import { useHelicopterSaver } from "./useHelicopterSaver";

export const HelicopterEditor = ({ helicopterId }) => {
  const {
    isFetching,
    formState,
    handleChangeEvent,
    handleNumberChange,
    handleCheckboxClick,
  } = useHelicopterEditorFormState(helicopterId);
  const { saveHelicopter, isSaving } = useHelicopterSaver(helicopterId);

  const handleOnSaveClick = () => saveHelicopter(formState);

  if (isFetching) return <Loader active />;

  return (
    <Form>
      <Segment className="colored">
        <Form.Checkbox
          label="Is published"
          checked={formState.isPublished}
          onChange={handleCheckboxClick}
          name="isPublished"
        />
        <Form.Input
          label="Display name"
          placeholder="Eurocopter 155 - 6 Seat"
          id="display-name-input"
          value={formState.name || ""}
          name="name"
          onChange={handleChangeEvent}
        />
        <Form.Group widths="equal">
          <NumberFormat
            onValueChange={handleNumberChange}
            value={formState.engineQuantity}
            customInput={Form.Input}
            label="Engine quantity"
            id="engine-quantity-input"
            type="number"
            name="engineQuantity"
            decimalScale={0}
            allowNegative={false}
            onFocus={(e) => e.target.select()}
          />

          <NumberFormat
            onValueChange={handleNumberChange}
            value={formState.seatsQuantity}
            name="seatsQuantity"
            customInput={Form.Input}
            label="Seats quantity"
            id="seats-quantity-input"
            type="number"
            decimalScale={0}
            allowNegative={false}
            onFocus={(e) => e.target.select()}
          />
        </Form.Group>
      </Segment>
      <Segment className="colored">
        <Form.Input
          id="luggage-allowance-short-input"
          label="Luggage allowance short"
          placeholder="e.g. up to 3 large and 2 small items"
          value={formState.luggageAllowanceShort || ""}
          name="luggageAllowanceShort"
          onChange={handleChangeEvent}
        />
        <Form.TextArea
          id="luggage-details-input"
          label="Luggage details"
          rows={10}
          placeholder={LuggageDetailsPlaceholder}
          value={formState.luggageAllowanceMarkdown || ""}
          name="luggageAllowanceMarkdown"
          onChange={handleChangeEvent}
        />
      </Segment>
      <Form.Button primary onClick={handleOnSaveClick} loading={isSaving}>
        Save helicopter
      </Form.Button>
    </Form>
  );
};

const LuggageDetailsPlaceholder = `# Summer

2 large, 3 medium size items

# Winter

## With Skis

2 large, 3 medium size suitcases + 5 ski bags

## Without skis
4 large, 3 medium suitcases`;
