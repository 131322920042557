// @ts-check
import { useState } from "react";

import { useOperator } from "../../operator/OperatorContext";
import { RouteEditorView } from "./RouteEditorView";
import { useCachedLocalRoutes } from "./useCachedLocalRoutes";
import { useRouteFormVehicles } from "./useRouteFormVehicles";
import { useRouteSaver } from "./useRouteSaver";

/**
 * @typedef {{
 *   id: string;
 *   name: string;
 *   basePrice: string;
 *   currency: string;
 * }} RouteFormVehicle
 */

export const RoutesEditorContainer = () => {
  const { operator } = useOperator();

  const { localRoutes, upsertRoute } = useCachedLocalRoutes();

  const [fromLocationId, setFromLocationId] = useState(null);
  const [toLocationId, setToLocationId] = useState(null);
  const [isApplyToReturnTripChecked, setIsApplyToReturnTripChecked] =
    useState(true);

  const { vehicles, onNewVehicles } = useRouteFormVehicles({
    fromLocationId,
    toLocationId,
    routes: localRoutes,
  });
  const { saveRoute, isSaving } = useRouteSaver({
    fromLocationId,
    toLocationId,
    onRouteSaved: upsertRoute,
    transferOperatorId: operator._id,
    isApplyToReturnTripChecked,
  });

  const onSaveClick = () => saveRoute(vehicles);

  return (
    <RouteEditorView
      fromLocationId={fromLocationId}
      toLocationId={toLocationId}
      setFromLocationId={setFromLocationId}
      setToLocationId={setToLocationId}
      formVehicles={vehicles}
      isSaving={isSaving}
      onNewFormVehicles={onNewVehicles}
      onSaveClick={onSaveClick}
      isApplyToReturnTripChecked={isApplyToReturnTripChecked}
      setIsApplyToReturnTripChecked={setIsApplyToReturnTripChecked}
    />
  );
};
