import { useEffect, useState } from "react";

import { snowtrade } from "../api/snowtrade";

export const useLocationOptions = () => {
  const [locationOptions, setLocationOptions] = useState([]);

  useEffect(() => {
    snowtrade
      .get("/api/transfer-locations")
      .then((response) =>
        setLocationOptions(
          transferLocationsToLocationOptions(response.data.locations)
        )
      );
  }, []);

  return locationOptions;
};

/** @param {import("../../TransferLocation").TransferLocation[]} transferLocations */
const transferLocationsToLocationOptions = (transferLocations) =>
  transferLocations.map((location) => ({
    key: location._id,
    value: location._id,
    text: location.name,
    description: location.country,
  }));
