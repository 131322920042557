let isDevEnvironment;

try {
  isDevEnvironment = window.location.href.includes("localhost");
} catch (e) {
  isDevEnvironment = true;
}

export const baseURL = isDevEnvironment
  ? "http://localhost:5000"
  : "https://db.snowtrade-royale.com";
