import ReactTable from "react-table-v6";

import { EditLocationButton } from "./EditLocationButton";

export const LocationsTable = ({ locations, isLoading, refetchLocations }) => {
  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "City", accessor: "city" },
    { Header: "Country", accessor: "country" },
    {
      Header: "",
      maxWidth: 50,
      Cell: (row) => (
        <EditLocationButton
          location={row.original}
          onLocationEdited={refetchLocations}
        />
      ),
    },
  ];

  return (
    <ReactTable
      columns={columns}
      data={locations}
      defaultPageSize={10}
      className="-striped -highlight"
      loading={isLoading}
    />
  );
};
