import { Navigate, useLocation } from "react-router-dom";
import { Loader } from "semantic-ui-react";

import { useAuth } from "./authContext";

export const RequireAuth = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();

  if (auth.isFetching) {
    return <Loader active>Loading user...</Loader>;
  }

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
