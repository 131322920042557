import { snowtrade } from "../../api/snowtrade";
import { deviceId } from "../deviceId";

export const sendLoginRequest = (credentials) =>
  snowtrade
    .post("/auth/local", { ...credentials, tokenExpiration: "never", deviceId })
    .then((response) => response.data);

export const sendLogoutRequest = () =>
  snowtrade.get("/api/logout", { params: { deviceId } });
