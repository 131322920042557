import { useEffect, useState } from "react";

import { StreamChat } from "stream-chat";

import { snowtrade } from "../../api/snowtrade";
import { useAuth } from "../../authentication/authContext";
import { isDevServer } from "../../helpers/isDevServer";
import { ChatClientContext } from "./ChatClientContext";

const GETSTREAM_IO_KEY = isDevServer ? "czger37276dd" : "398uwx66hkq6";

export const ChatClientProvider = ({ children }) => {
  const { user } = useAuth();

  const [chatClient, setChatClient] = useState(null);

  const connectUser = async () => {
    const client = StreamChat.getInstance(GETSTREAM_IO_KEY);

    if (client && client.user) {
      await client.disconnectUser();
    }

    const chatUser = {
      id: user._id,
      name: `${user.firstName} ${user.lastName}`,
    };
    if (user.role !== "admin") {
      chatUser.image =
        "https://str-images.s3.eu-central-1.amazonaws.com/other/helicopter-icon.png";
    }

    const token = await fetchChatUserToken();
    await client.connectUser(chatUser, token);

    setChatClient(client);
  };

  useEffect(() => {
    connectUser();
    return chatClient?.disconnectUser();
  }, []);

  return (
    <ChatClientContext.Provider value={{ client: chatClient }}>
      {children}
    </ChatClientContext.Provider>
  );
};

const fetchChatUserToken = () =>
  snowtrade
    .get("/api/current-user/stream-chat-token")
    .then((response) => response.data.token);
