import { useTransferLocations } from "../useTransferLocations";
import { LocationsTable } from "./LocationsTable";
import { NewLocationButton } from "./NewLocationButton";

export const LocationsTableContainer = () => {
  const { isFetching, locations, refetchLocations } = useTransferLocations();

  return (
    <>
      <LocationsTable
        locations={locations}
        isLoading={isFetching}
        refetchLocations={refetchLocations}
      />
      <NewLocationButton onLocationCreated={refetchLocations} />
    </>
  );
};
