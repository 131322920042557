import { Container, Header } from "semantic-ui-react";

import { RoutesEditor } from "./RoutesEditor";

export const RoutesPage = () => {
  return (
    <Container>
      <Header as="h1">Routes</Header>
      <RoutesEditor />
    </Container>
  );
};
