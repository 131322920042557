import "./SemanticUiOverride.css";
import "react-toastify/dist/ReactToastify.css";
import "react-table-v6/react-table.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container } from "semantic-ui-react";

import { AuthProvider } from "../authentication/authContext";
import { LoginPage } from "../authentication/LoginPage";
import { LogoutPage } from "../authentication/LogoutPage";
import { RequireAuth } from "../authentication/RequireAuth";
import { BaseErrorBoundary } from "../BaseErrorBoundary";
import { Chat } from "../Chat";
import { ChatClientProvider } from "../Chat/ChatClientContext";
import { FleetPage } from "../fleet/FleetPage";
import { VehicleEditorPage } from "../fleet/VehicleEditorPage";
import { LocationsPage } from "../locations/LocationsPage";
import { Menu } from "../Menu";
import { OperatorWaiter } from "../operator/OperatorContext";
import { OperatorProvider } from "../operator/OperatorContext/OperatorProvider";
import { RoutesPage } from "../routes/RoutesPage";

export const App = () => (
  <BrowserRouter>
    <AuthProvider>
      <BaseErrorBoundary>
        <Routes>
          <Route path="/login" element={<LoginPage />} />

          <Route
            path="/*"
            element={
              <RequireAuth>
                <ProtectedRoutes />
              </RequireAuth>
            }
          />
        </Routes>
      </BaseErrorBoundary>
    </AuthProvider>
    <ToastContainer autoClose={3000} position="bottom-right" />
  </BrowserRouter>
);

const ProtectedRoutes = () => {
  // useTracking();
  return (
    <OperatorProvider>
      <ChatClientProvider>
        <Menu />
        <OperatorWaiter>
          <Routes>
            <Route path="logout" element={<LogoutPage />} />
            <Route path="fleet" element={<FleetPage />} />
            <Route
              path="fleet/:vehicleId/editor"
              element={<VehicleEditorPage />}
            />
            <Route path="routes" element={<RoutesPage />} />
            <Route path="locations" element={<LocationsPage />} />

            <Route
              path=""
              element={
                <Container>
                  <Chat />
                </Container>
              }
            />
          </Routes>
        </OperatorWaiter>
      </ChatClientProvider>
    </OperatorProvider>
  );
};
