import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Button, Container, Header, Icon, Grid } from "semantic-ui-react";

import { useAuth } from "../authContext";

export const LogoutPage = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const userString = `${user.firstName} ${user.lastName}`;

  const onLogOutClick = () => {
    setIsLoading(true);
    logout()
      .then(() => navigate("/login"))
      .catch(console.error);
  };

  return (
    <Container>
      <Grid centered style={{ height: "50vh", alignContent: "center" }} padded>
        <div>
          <Header as="h1">
            <Icon name="user" />
            {userString}
          </Header>
          <Button primary onClick={onLogOutClick} loading={isLoading}>
            Log Out
          </Button>
        </div>
      </Grid>
    </Container>
  );
};
