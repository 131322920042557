import "./VehiclesTable.css";

import ReactTable from "react-table-v6";
import { Container, Header, Icon } from "semantic-ui-react";

import { Tooltip } from "../../../Tooltip";
import { CurrencySelect } from "./CurrencySelect";
import { VehiclePriceInput } from "./VehiclePriceInput";

/**
 * @param {object} props
 * @param {import("../RoutesEditorContainer").RouteFormVehicle[]} props.vehicles
 * @param {function} props.onVehiclesChange
 */
export const RouteVehiclesTable = ({ vehicles = [], onVehiclesChange }) => {
  const makeVehicleUpdater = (index) => (update) => {
    const newVehicles = [...vehicles];
    const newVehicle = { ...newVehicles[index], ...update };

    newVehicles[index] = newVehicle;

    onVehiclesChange(newVehicles);
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: (
        <span>
          Price{" "}
          <Tooltip tooltip={priceTooltip}>
            <Icon name="info circle" />
          </Tooltip>
        </span>
      ),
      maxWidth: 300,
      Cell: ({ index, original }) => (
        <VehiclePriceInput
          value={original.basePrice}
          currency={original.currency}
          onUpdate={makeVehicleUpdater(index)}
        />
      ),
    },
    {
      Header: "Currency",
      maxWidth: 100,
      className: "currency",
      Cell: ({ index, original }) => (
        <CurrencySelect
          value={original.currency}
          onUpdate={makeVehicleUpdater(index)}
        />
      ),
    },
  ];

  return <ReactTable data={vehicles} columns={columns} defaultPageSize={10} />;
};

const priceTooltip = (
  <Container text>
    <Header>Helicopter price</Header>
    <p>
      Please enter the fixed price for the given route and the given helicopter
      incl. VAT.
    </p>
    <p>
      In the event that this helicopter does not provide the given route, just
      leave the field empty.
    </p>
  </Container>
);
