import { Link } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";

import { OperatorDropdown } from "./OperatorDropdown";

import "./AdminUserItem.css";

export const AdminUserItem = () => {
  return (
    <Menu.Item position="right" className="admin user" link>
      <Link to="/logout">
        <Icon name="user" />
      </Link>

      <OperatorDropdown />
    </Menu.Item>
  );
};
