import { useEffect, useState } from "react";

import { get } from "lodash";
import { Icon } from "semantic-ui-react";

import { useChatClient } from "../Chat/ChatClientContext";

export const UnreadMessagesItem = () => {
  const { client } = useChatClient();

  const [unreadCount, setUnreadCount] = useState();

  useEffect(() => {
    if (!client) return;

    const unreadCountSubscriber = client.on((event) => {
      if (isFinite(event.total_unread_count)) {
        setUnreadCount(event.total_unread_count);
        return;
      }

      // Set initial unread_count from client as soon as it is loaded.
      if (event.type === "connection.changed" && event.online) {
        setTimeout(() => {
          if (isFinite(unreadCount)) return;
          setUnreadCount(get(client, "user.unread_count"));
        }, 200);
      }
    });

    return () => unreadCountSubscriber.unsubscribe();
  }, [client]);

  if (!client) return null;

  if (!unreadCount) return null;

  return (
    <span>
      <Icon name="talk" />
      {unreadCount}
    </span>
  );
};
