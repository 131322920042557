import { Container, Header } from "semantic-ui-react";

import { LocationsTable } from "./LocationsTable";

export const LocationsPage = () => {
  return (
    <Container>
      <Header as="h1">My Locations</Header>
      <LocationsTable />
    </Container>
  );
};
