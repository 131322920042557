// @ts-check
import { useEffect, useState } from "react";

import { isEmpty, omitBy } from "lodash";

const INITIAL_ERROR = {
  name: "",
  city: "",
  country: "",
};

export const useLocationFormValidation = (formState) => {
  const [error, setError] = useState(INITIAL_ERROR);
  const [isValidationActive, setIsValidationActive] = useState(false);

  const activateValidation = () => setIsValidationActive(true);

  const validateForm = () => {
    const trimmed = {
      name: formState.name.trim(),
      city: formState.city.trim(),
      country: formState.country.trim(),
    };

    const newError = { ...INITIAL_ERROR };
    if (!trimmed.name) {
      newError.name = "Please enter the name of the location";
    }
    if (!trimmed.city) {
      newError.city = "Please enter the city the location is in";
    }
    if (!trimmed.country) {
      newError.country = "Please enter the country the location is in";
    }

    setError(newError);
    const isValid = isEmpty(omitBy(newError, isEmpty));

    return { value: trimmed, isValid };
  };

  useEffect(() => {
    validateForm();
  }, [formState]);

  return { error, validateForm, isValidationActive, activateValidation };
};
