import { Select } from "semantic-ui-react";

export const CurrencySelect = ({ value, onUpdate }) => {
  return (
    <Select
      compact
      value={value}
      options={[
        { text: "EUR", value: "EUR" },
        { text: "CHF", value: "CHF" },
      ]}
      onChange={(e, { value }) => onUpdate({ currency: value })}
    />
  );
};
