import { useState } from "react";

import { get } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "../authContext";

import "./LoginPage.css";

export const useLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const logIn = (credentials) => {
    setIsLoading(true);
    auth
      .login(credentials)
      .then(() => {
        let navigateTo = get(location, "state.from") || "/";
        if (navigateTo.pathname === "/logout") navigateTo = "/";
        navigate(navigateTo, { replace: true });
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Username / Password not right");
      });
  };

  return { logIn, isLoading };
};
