import { useParams } from "react-router-dom";
import { Container, Header } from "semantic-ui-react";

import { HelicopterEditor } from "./HelicopterEditor";

export const VehicleEditorPage = () => {
  const { vehicleId } = useParams();
  return (
    <Container>
      <Header as="h1">Helicopter Editor</Header>
      <HelicopterEditor helicopterId={vehicleId} />
    </Container>
  );
};
