import { useContext } from "react";

import { OperatorContext } from "./OperatorContext";

/**
 * @returns {{
 *   operator?: import("../Operator").Operator;
 *   isFetching: boolean;
 *   hardSetOperator: function;
 * }}
 */
export const useOperator = () => useContext(OperatorContext);
